<template>
  <div>
    <div class="wrok-theme-create">
      <div class="manage-wrapper">
        <div class="wrok-theme-create-container">
          <h3 class="create_title">创建论坛</h3>
          <div class="content">
            <el-form label-width="130px" :rules="rules" ref="ruleForm" :model="form">
              <el-form-item label="应用场景">
                <div class="scene-box flex-align">
                  <div class="scene-item flex-column" :class="{ 'scene-active': sceneIndex == index }"
                    v-for="(item, index) in sceneList" :key="index" @click="selectScene(item, index)">
                    <div class="title flex-align-center">{{ item.title }}</div>
                    <div class="introduce">
                      <span>{{ item.introduce }}</span>
                      <span>{{ item.introduce2 }}</span>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="论坛名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入论坛名称"></el-input>
              </el-form-item>
              <el-form-item label="论坛分类" prop="categoryId">
                <el-select v-model="form.categoryId" placeholder="请选择论坛分类">
                  <el-option :label="item.attributes.name" :value="item.id" v-for="(item, index) in forumList"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="论坛图标">
                <UploadCover @uploadCover="handleAvatarChange" @lxupload="lxupload"></UploadCover>
              </el-form-item>
              <el-form-item label="论坛介绍">
                <el-input type="textarea" v-model="form.introduce" :autosize="{ minRows: 5, maxRows: 8}"></el-input>
              </el-form-item>
              <el-form-item label="应用设置">
                <el-checkbox-group v-model="form.application">
                  <el-checkbox :label="item.value" name="type" v-for="(item, index) in setList" :key="index">{{ item.label
                  }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="用户组" prop="userGroup">
                <el-cascader :options="userGroupOptionList" :props="{ multiple: true, value: 'id', emitPath: false }"
                  clearable filterable v-model="form.userGroup" @change="userGroupChange" collapse-tags
                  placeholder="请选择用户组">
                </el-cascader>
              </el-form-item>
              <el-form-item class="el-form-item-btns">
                <el-button @click="cancel" size="mini">取消</el-button>
                <el-button type="primary" @click="onSubmit('ruleForm')" size="mini">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from "vuex";
export default {
  data() {
    return {
      sceneIndex: 0,
      form: {
        name: '',
        categoryId: '',
        introduce: '',
        type: 2,
        application: [],
        cover: '',
        groupIds: []
      },
      rules: {
        name: [
          { required: true, message: '请输入论坛名称', trigger: 'blur' },
        ],
        categoryId: [
          { required: true, message: '请选择论坛分类', trigger: 'change' }
        ],
        userGroup: [
          { required: true, message: '请选择用户组', trigger: 'change' }
        ]
      },
      sceneList: [
        {
          id: 2,
          title: '知识沉淀',
          introduce: '提供知识沉淀文档模板',
          introduce2: '首页默认展示文档分类'
        },
        {
          id: 3,
          title: '项目管理',
          introduce: '提供项目管理文档模板',
          introduce2: '首页默认展示文档分类和日历'
        },
        {
          id: 4,
          title: '协会培训',
          introduce: '提供活动培训总结模板',
          introduce2: '首页默认展示活动日历'
        },
        {
          id: 1,
          title: '自定义',
          introduce: '其他场景定制',
          introduce2: ''
        }
      ],
      forumList: [],
      setList: [
        {
          label: '文档',
          value: 'knowledge'
        },
        {
          label: '轻享',
          value: 'share'
        },
        {
          label: '活动',
          value: 'event'
        },
        {
          label: '问卷',
          value: 'survey'
        },
        {
          label: '考试',
          value: 'exam'
        },
        {
          label: '课堂',
          value: 'course'
        },
        {
          label: '直播',
          value: 'liveroom'
        },
        {
          label: '相册',
          value: 'album'
        },
        {
          label: '文集',
          value: 'collection'
        },
        {
          label: '项目',
          value: 'roadmap'
        }
      ],
      userGroupOptionList: [],
      lxLogoInfo: {
        assetId: '',
        url: ''
      }
    }
  },

  methods: {
    selectScene(item, index) {
      this.sceneIndex = index;
      this.form.type = item.id;
    },
    // 获取K吧选项
    async getCategories() {
      let resData = await this.$Api.space.getCategories()
      console.log('K吧选项:', resData);
      this.forumList = resData.data.categories;
    },
    // 获取用户组列表
    async getUserGroupOptionList() {
      let resData = await this.$Api.Form.getUserGroup(this.currentProject.id)
      // console.log('用户组列表', resData)
      this.userGroupOptionList = resData.data;
    },
    // 用户组选中变更
    userGroupChange(val) {
      console.log(val)
      if (!val || JSON.stringify(val) == '[]') {
        return;
      }
      this.form.groupIds = val
    },
    handleAvatarChange(data) {
      console.log('头像:', data)
      this.form.cover = data.md5
    },
    cancel() {
      this.$router.go(-1)
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            projectId: this.currentProject.id,
            name: this.form.name,
            type: this.form.type,
            categoryId: this.form.categoryId,
            application: this.form.application,
            groupIds: this.form.groupIds,
            lxLogoInfo: this.lxLogoInfo,
            pic: this.form.cover
          }
          console.log('提交参数:', data);
          this.$Api.space.createKBar(data)
            .then(resData => {
              console.log('提交结果:', resData);
              if (resData.code == 200) {
                this.$notify({
                  title: '成功',
                  message: '创建成功',
                  type: 'success'
                })
                this.$router.go(-1)
              } else {
                this.$notify({
                  title: '失败',
                  message: '创建失败,请稍后再试',
                })
              }
            })
            .catch(err => {
              console.log(err);
              this.$notify({
                title: '失败',
                message: '创建失败,请稍后再试',
              })
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    async lxupload(file) {
      console.log('K吧file:', file);
      let data = {
        file: file.raw
      }
      let resData = await this.$Api.space.upload(data);
      console.log('lx----', resData);
      this.lxLogoInfo.assetId = JSON.parse(resData.data).asset_id;
      this.lxLogoInfo.url = JSON.parse(resData.data).url;
    }
  },
  watch: {},
  computed: {
    ...mapState({
      currentProject: state => state.user.currentProject,
    })
  },
  mounted() {
    this.getUserGroupOptionList();
    this.getCategories();
    this.$nextTick(() => {
      this.$route.meta.title = '创建K吧'
      this.$store.commit('breadPageNameChange', '创建K吧')
    })
  }
};
</script>

<style lang="less" scoped>
@import url("./less/create_space.less");
</style>